<template>
    <div class="wrap">
        <div class="layout">
            <div class="box">
                <div class="close" @click="close()">返回</div>
                <div class="content">
                    <div class="title">活动详情</div>
                    <p>
                        <a href="http://878e.cc/" target="_blank">【22彩票】</a>发红包雨拉~~~~人人都能领取！每天准时开抢！惊喜不断~~~
                    </p>
                    <p>开放时间：每天北京时间凌晨02:00:00至23:59:59分开放。</p>
                    <p>活动条件：当日有效存款总额达到100元以上，次日02:00:00至23:59:59期间即可参与红包雨活动，抽奖次数根据存款金额等级而获得，完全随机中奖。</p>
                    <div class="table">
                        <table>
                            <thead>
                                <tr>
                                    <th>日存款</th>
                                    <th>次数</th>
                                    <th>日存款</th>
                                    <th>次数</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>≥100元</td>
                                    <td>1次</td>
                                    <td>≥3000元</td>
                                    <td>2次</td>
                                </tr>
                                <tr>
                                    <td>≥5000元</td>
                                    <td>3次</td>
                                    <td>≥10000元</td>
                                    <td>4次</td>
                                </tr>
                                <tr>
                                    <td>≥50000元</td>
                                    <td>5次</td>
                                    <td>≥100000元</td>
                                    <td>7次</td>
                                </tr>
                                <tr>
                                    <td>≥300000元</td>
                                    <td>9次</td>
                                    <td>≥500000元</td>
                                    <td>12次</td>
                                </tr>
                                <tr>
                                    <td>≥1000000元</td>
                                    <td>15次</td>
                                    <td>≥3000000元</td>
                                    <td>20次</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p>
                        <span>◆奖品内容◆</span>
                    </p>
                    <p>现金红包，iPhone XS（256G）更多奖品持续更新中.......</p>
                    <p>
                        随手一抢中奖100%，奖了还不够？没关系！凡昨日存款总额 100 以上的会员，抽中我司特定幸运数值，即可申请相对应的转运彩金！
                        <a
                            href="http://878e.cc/"
                            target="_blank"
                        >【22彩票】</a>实力狂欢赠送，感恩有你，福运连绵！
                    </p>
                    <div class="table">
                        <table>
                            <thead>
                                <tr>
                                    <th>活动对象</th>
                                    <th>幸运数值</th>
                                    <th>转运金</th>
                                    <th>活动对象</th>
                                    <th>幸运数值</th>
                                    <th>转运金</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td rowspan="12">昨日存款总额100元+</td>
                                    <td>1.23</td>
                                    <td>12</td>
                                    <td rowspan="12">昨日存款总额1000元+</td>
                                    <td>1.23</td>
                                    <td>13</td>
                                </tr>
                                <tr>
                                    <td>2.34</td>
                                    <td>22</td>
                                    <td>2.34</td>
                                    <td>33</td>
                                </tr>
                                <tr>
                                    <td>3.45</td>
                                    <td>36</td>
                                    <td>3.45</td>
                                    <td>78</td>
                                </tr>
                                <tr>
                                    <td>4.56</td>
                                    <td>76</td>
                                    <td>4.56</td>
                                    <td>93</td>
                                </tr>
                                <tr>
                                    <td>6.78</td>
                                    <td>158</td>
                                    <td>6.78</td>
                                    <td>188</td>
                                </tr>
                                <tr>
                                    <td>7.89</td>
                                    <td>328</td>
                                    <td>7.89</td>
                                    <td>518</td>
                                </tr>
                                <tr>
                                    <td>1.11</td>
                                    <td>111</td>
                                    <td>1.11</td>
                                    <td>128</td>
                                </tr>
                                <tr>
                                    <td>2.22</td>
                                    <td>222</td>
                                    <td>2.22</td>
                                    <td>328</td>
                                </tr>
                                <tr>
                                    <td>3.33</td>
                                    <td>333</td>
                                    <td>3.33</td>
                                    <td>518</td>
                                </tr>
                                <tr>
                                    <td>6.66</td>
                                    <td>666</td>
                                    <td>6.66</td>
                                    <td>688</td>
                                </tr>
                                <tr>
                                    <td>8.88</td>
                                    <td>888</td>
                                    <td>8.88</td>
                                    <td>988</td>
                                </tr>
                                <tr>
                                    <td>9.99</td>
                                    <td>999</td>
                                    <td>9.99</td>
                                    <td>1588</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p>
                        <span>【注】</span>满足存款条件的会员，砸中之后，其中只要单个红包中金额为幸运数值即可申请，若出现重复数值视为一次，每天仅限申请一次，次日视为自动放弃！
                    </p>
                    <p>
                        <span>【注】</span>满足存款条件的会员，砸中之后，其中只要单个红包中金额为幸运数值即可申请，若出现重复数值视为一次，抽中豹子数值按最高数值计算，每天申请次数仅限一次，逾期视为自动放弃！
                    </p>
                    <p>范例：昨日存款总额10000以上的会员，砸到单个金额为1.23+3.45+7.89即可申请转运礼包：13+78+518=609元！</p>
                    <p>昨日存款总额10000以上的会员，砸到单个金额为1.11+1.11（重复数值计算一次）+9.99（豹子数值按最高计算）即可申请转运礼包128+1588=1716元！</p>
                    <div class="title">活动细则</div>
                    <p>1.每天02:00:00至23:59:59期间开始参与，且需在当天完成砸金蛋，逾期则视为自动放弃活动资格；</p>
                    <p>2.所获得奖金只需1倍流水，即可申请提款。</p>
                    <p>3.此活动不包含香港六合彩。转运彩金每天只能挑选一个金额申请一次。</p>
                    <p>4.获得中奖加赠要求的玩家，请在次日00:00:00跟在线客服申请，逾期则视为自动放弃！</p>
                    <p>5.凡是抽中“现金筹码”的玩家，无需申请，系统立即自动添加到中奖玩家账号上，如超过5分钟未派送，直接联系在线客服受理查询即可。</p>
                    <p>6.凡是抽中“iPhone XS”（颜色随机，256G，国行）的实物奖品的玩家，奖品不可折现；请务必于中奖后48小时内联系专员QQ【9996222】申请，提供快递收货地址、姓名及联系电话，奖品将在中奖玩家提供具体收货地址后十个工作日内寄出，请耐心等待。如中奖后48小时内未联系我司客服确认收货地址或因个人提供的收件信息不完整、不正确、电话无法联系上导致物品无法寄达，快递退回的情况，均视为自动放弃且不再安排寄送，请勿与客服就此理论。</p>
                    <p>7.此活动为【22彩票】系统程序自动运行，获奖的概率完全遵循力学及自然概率，不涉及任何人工操作，抽奖活动以系统判定为准，不得争议。</p>
                    <div class="title min">优惠规则与条款</div>
                    <p>1. 所有优惠以人民币(CNY)为结算金额，以美东时间(EDT)为计时区间；</p>
                    <p>2. 每位玩家﹑每户﹑每一住址 、每一电子邮箱地址﹑每一电话号码﹑相同支付方式(相同借记卡/信用卡/银行账户) 及IP地址只能享有一次优惠；若会员有重复申请账号行为，公司保留取消或收回会员优惠彩金的权利；</p>
                    <p>3. 22彩票的所有优惠特为玩家而设，如发现任何团体或个人，以不诚实方式套取红利或任何威胁、滥用公司优惠等行为，公司保留冻结、取消该团体或个人账户及账户结余的权利；</p>
                    <p>4. 若会员对活动有争议时，为确保双方利益，杜绝身份盗用行为，22彩票有权要求会员向我们提供充足有效的文件，用以确认是否享有该优惠的资质；</p>
                    <p>5. 当参与优惠会员未能完全遵守、或违反、或滥用任何有关公司优惠或推广的条款，又或我们有任何证据有任何团队或个人投下一连串的关联注单，籍以造成无论赛果怎样都可以确保可以从该存款红利或其他推广活动提供的优惠获利，22彩票城保留权利向此团队或个人停止、取消优惠或索回已支付的全部优惠红利。此外，公司亦保留权利向这些客户扣取相当于优惠红利价值的行政费用，以补偿我们的行政成本；</p>
                    <p>6. 22彩票保留对活动的最终解释权，以及在无通知的情况下修改、终止活动的权利，适用于所有优惠。</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'detail',
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="less" scoped>
.wrap {
    position: fixed;
    top: 2.4rem;
    left: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
    height: calc(100% - 6rem);
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #88000e;
    z-index: 9999;
    overflow: hidden;
    .layout {
        position: relative;
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        height: 100%;
    }
    .box {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow-y: auto;
    }
    .content {
        padding: 0.6rem 0.4rem;
        font-size: 0.8rem;
        .table {
            width: 100%;
            padding: 0.6rem 0;
            table {
                width: 100%;
                border-collapse: collapse;
                border-spacing: 0;
            }
            th {
                padding: 0.1rem;
                border: solid 1px #ae1927;
                background: #f2c40f;
                color: #640404;
                font-weight: 400;
            }
            td {
                padding: 0.1rem;
                border: solid 1px #ae1927;
                color: #fbf6f7;
            }
        }
        a {
            color: #ff0;
        }
        p {
            line-height: 1.8;
            text-align: left;
            color: #fff;
            span {
                color: #ff0;
            }
        }
    }
    .title {
        width: 100%;
        padding: 0.4rem 0;
        background: url("../assets/images/wt.png") center no-repeat;
        background-size: auto 1.7rem;
        font-size: 1.2rem;
        color: #640404;
        font-weight: bold;
    }
    .min {
        padding: 1rem 0;
        font-size: 0.9rem;
    }
    .close {
        position: fixed;
        top: 0.4rem;
        right: 0.4rem;
        width: 4rem;
        height: 1.6rem;
        line-height: 1.6rem;
        border-radius: 0.2rem;
        background: #fff89a;
        color: #640404;
        font-weight: 400;
        font-size: 1rem;
        font-weight: bold;
    }
}
</style>
