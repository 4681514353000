<template>
    <div class="wrap">
        <div class="layout">
            <div class="box">{{ closeStr }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'closed',
    props: {
        closeStr: {
            type: String,
            default: null
        }
    }
}
</script>

<style lang="less" scoped>
.wrap {
    position: fixed;
    top: 0;
    left: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    .layout {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        height: 100%;
    }
    .box {
        width: 90%;
        margin: 0 auto;
        padding: 0.6rem;
        text-align: left;
        background: #ae3727;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
        -moz-border-radius: 6px;
        -webkit-border-radius: 6px;
        border-radius: 6px;
        color: #ff0;
        font-size: 0.9rem;
    }
}
</style>
