<template>
    <div class="main">
        <div class="header"></div>
        <div class="wrapper">
            <div class="bg1"></div>
            <div class="status">
                <CountDownWap
                    :times="countDownData.times"
                    :title="countDownData.title"
                    @start="state()"
                />
                <div class="openBtn" @click="open()" v-points="600"></div>
            </div>
            <div class="scroll">
                <div class="box">
                    <div class="title"></div>
                    <div class="list">
                        <vueSeamless :data="scroll.list" :class-option="scroll.option">
                            <p v-for="(item, index) in scroll.list" :key="index">
                                <span class="name">
                                    恭喜会员
                                    <font>{{ item.name }}</font>
                                </span>
                                <span>
                                    抢中红包
                                    <font>{{ item.prize }}</font>
                                </span>
                                <span>{{ item.times }}</span>
                            </p>
                        </vueSeamless>
                    </div>
                </div>
            </div>
            <div class="detail" @click="goDetail()">点击查看详情</div>
        </div>
        <div class="footer">
            <template v-for="(item, index) in menus">
                <a v-if="item.type === '0'" :key="index" @click="searchIsShow = true">
                    <i class="iconfont icon-qianbao"></i>
                    <p>红包查询</p>
                </a>
                <template v-else>
                    <a v-if="index === 0" :key="index" target="_blank" :href="item.url">
                        <i class="iconfont icon-home"></i>
                        <p>{{ item.name }}</p>
                    </a>
                    <a v-else-if="index === 2" :key="index" target="_blank" :href="item.url">
                        <i class="iconfont icon-app"></i>
                        <p>手机APP</p>
                    </a>
                    <a v-else-if="index === 5" :key="index" target="_blank" :href="item.url">
                        <i class="iconfont icon-kefu"></i>
                        <p>在线客服</p>
                    </a>
                    <template v-else></template>
                </template>
            </template>
        </div>
        <RedPack
            :amount="15"
            :size="40"
            :speed="2"
            :wind="0"
            :opacity="0.8"
            :swing="10"
            :image="floatImg"
            :zIndex="null"
            :resize="true"
            v-points="600"
            v-if="redPacketIsShow"
            @open="open()"
        />
        <PackWap
            :logo="logo"
            :username="username"
            :score="score"
            @close="packIsShow = false"
            v-if="packIsShow"
        />
        <Detail @close="detailIsShow = false" v-if="detailIsShow" />
        <LoginWap @close="loginIsShow = false" v-if="loginIsShow" />
        <SearchWap @close="searchIsShow = false" v-if="searchIsShow" />
        <ClosedWap :close-str="closeInfo.str" v-if="closeInfo.isShow" />
    </div>
</template>

<script>
import Logo from 'assets/images/logo.jpg'
import RedPack from 'components/RedPack'
import PackWap from 'components/PackWap'
import CountDownWap from 'components/CountDownWap'
import Detail from 'components/Detail'
import LoginWap from 'components/LoginWap'
import SearchWap from 'components/SearchWap'
import ClosedWap from 'components/ClosedWap'
import vueSeamless from 'vue-seamless-scroll'

export default {
    name: 'wap',
    metaInfo() {
        return {
            meta: [
                {
                    name: 'viewport',
                    content: 'width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no'
                }
            ]
        }
    },
    components: {
        RedPack,
        PackWap,
        CountDownWap,
        Detail,
        LoginWap,
        SearchWap,
        vueSeamless,
        ClosedWap
    },
    created() {
        this.getList()
    },
    mounted() {
        this.state()
    },
    data() {
        return {
            floatImg: require('assets/images/redpack.png'),
            logo: Logo,
            redPacketIsShow: false,
            packIsShow: false,
            loginIsShow: false,
            searchIsShow: false,
            detailIsShow: false,
            countDownData: {
                times: 0,
                title: ''
            },
            menus: [],
            scroll: {
                option: {
                    step: 1,
                    limitMoveNum: 9,
                    openTouch: false,
                    waitTime: 1,
                    direction: 1,
                    singleHeight: 30
                },
                list: []
            },
            closeInfo: {
                str: '',
                isShow: false
            }
        }
    },
    methods: {
        // 检测活动状态
        async state() {
            const { data } = await this.$axios.get('ajax.php', { params: { action: 'status' } })
            // console.log(data)
            this.menus = data.menu
            this.countDownData.times = data.timer

            if (data.stat === 0) {
                this.closeInfo.str = data.title
                this.closeInfo.isShow = true
                return false
            }

            this.countDownData.title = data.title

            if (data.stat === 1) {
                this.redPacketIsShow = true // 显示红包雨
                return false
            }
        },
        open() {
            if (this.username === '') {
                this.loginIsShow = true
                return false
            }

            if (this.score <= 0) {
                alert('没有机会了')
                return false
            }

            this.packIsShow = true
        },
        async getList() {
            const { data } = await this.$axios.get('ajax.php', { params: { action: 'winlist' } })
            // console.log(data)
            this.scroll.list = data
        },
        goDetail() {
            this.detailIsShow = true
        }
    },
    computed: {
        username() {
            return this.$store.state.userData.username
        },
        score() {
            return this.$store.state.userData.score
        }
    }
}
</script>

<style lang="less" scoped>
@import "../../assets/font/iconfont.css";
.main {
    min-height: 100vh;
    background: #88000e;
}
.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 2.4rem;
    z-index: 20;
    background: url("../../assets/images/logo.jpg") #7c0000 center no-repeat;
    background-size: auto 1.6rem;
    border-bottom: 1px solid #f2d821;
}
.wrapper {
    padding: 2.4rem 0 3.6rem 0;
    .bg1 {
        height: 13rem;
        background-image: url("../../assets/images/wbg1.jpg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }
    .status {
        height: 8rem;
        background-image: url("../../assets/images/wbg2.jpg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        .openBtn {
            width: 6rem;
            height: 2rem;
            margin: 0.4rem auto 0 auto;
            background: url("../../assets/images/searchBtn.png") no-repeat;
            background-size: cover;
            cursor: pointer;
        }
    }
    .scroll {
        padding: 3rem 0 1.4rem 0;
        .box {
            position: relative;
            width: 96%;
            margin: 0 auto;
            padding: 2.2rem 0 0.5rem 0;
            border: 1px solid #c9613a;
            background: #c60f0f;
            border-radius: 0.1rem;
        }
        .title {
            position: absolute;
            top: -1.3rem;
            width: 100%;
            height: 3rem;
            background: url("../../assets/images/winWt.png") center no-repeat;
            background-size: auto 3rem;
        }
        .list {
            height: 7rem;
            overflow: hidden;
        }
        p {
            display: flex;
            justify-content: space-between;
            padding: 0 0.4rem;
            line-height: 1.6rem;
            font-size: 0.9rem;
            span {
                color: #fff;
                font {
                    color: #ff0;
                }
            }
        }
    }
    .detail {
        display: inline-block;
        padding: 0.4rem 1rem;
        color: #fce9a5;
        font-size: 1rem;
        line-height: 0.86rem;
        text-align: center;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 1rem;
        cursor: pointer;
    }
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    border-top: 1px solid #f2d821;
    z-index: 20;
    a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 25%;
        height: 100%;
        border-right: 1px solid #5c1015;
        color: #ebcf76;
        i {
            font-size: 1.4rem;
        }
        font-size: 0.9rem;
    }
}
</style>
