<template>
    <div class="countDownBox">
        <div class="title">{{ title }}</div>
        <div class="countdown">
            <div class="txt">倒计时:</div>
            <div class="item">{{ day }}</div>
            <div class="txt">天</div>
            <div class="item">{{ hr }}</div>
            <div class="txt">时</div>
            <div class="item">{{ min }}</div>
            <div class="txt">分</div>
            <div class="item">{{ sec }}</div>
            <div class="txt">秒</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'countdown',
    props: {
        times: {
            type: Number,
            default: null
        },
        title: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            day: 0,
            hr: 0,
            min: 0,
            sec: 0
        }
    },
    mounted() {
        const time = setInterval(() => {
            if (this.flag === true) {
                clearInterval(time)
            }
            this.countdown()
        }, 500)
    },
    methods: {
        countdown() {
            const end = this.times * 1000
            const now = Date.parse(new Date())
            const msec = end - now
            const day = parseInt(msec / 1000 / 60 / 60 / 24)
            const hr = parseInt(msec / 1000 / 60 / 60 % 24)
            const min = parseInt(msec / 1000 / 60 % 60)
            const sec = parseInt(msec / 1000 % 60)
            this.day = day
            this.hr = hr > 9 ? hr : '0' + hr
            this.min = min > 9 ? min : '0' + min
            this.sec = sec > 9 ? sec : '0' + sec
            if (msec <= 0) {
                Object.assign(this.$data, this.$options.data())
                this.flag = true
                setTimeout(() => {
                    this.$emit('start')
                }, 2000)
            }
        }
    }
}
</script>

<style lang="less" scoped>
.countDownBox {
    .title {
        font-size: 1.2rem;
        font-weight: bold;
        color: #532512;
    }
    .countdown {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0.1rem;
        .item {
            font-size: 1.2rem;
            font-weight: bold;
            color: #ac1316;
        }
        .txt {
            padding: 0 5px;
            font-size: 1.2rem;
            font-weight: bold;
            color: #532512;
        }
    }
}
</style>
